import useSWR from 'swr'

const apiBaseUrl = 'https://dkpushkin-wp.marketto.ru/wp-json/wp/v2'

const apiBaseOptions = {
  method: 'GET',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json;charset=UTF-8',
  },
}

const fetcher = (url) => fetch(`${apiBaseUrl}/${url}`, apiBaseOptions).then((res) => res.json())

export const useEventsData = () => {
  const { data, error } = useSWR('events', fetcher)

  return {
    events: data ?? [],
    isLoading: !error && !data,
    isError: error,
  }
}

export const useWorkshopsData = () => {
  const { data, error } = useSWR('workshops?per_page=100', fetcher)

  return {
    workshops: data ?? [],
    isLoading: !error && !data,
    isError: error,
  }
}
