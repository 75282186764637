import { mdiClose } from '@mdi/js'
import Icon from './Icon'

function ItemCard({
  label,
  title,
  time,
  image,
  className,
  mainHeight = 'h-64 lg:h-auto',
  isMain,
  isHoverable,
  onClick,
  onCloseClick,
}) {
  const date = time
    ? new Date(time).toLocaleDateString('ru-RU', {
        day: 'numeric',
        month: 'long',
        hour: 'numeric',
        minute: 'numeric',
      })
    : null

  const titleDateSpanClassName = image ? 'bg-white inline-block px-1 py-0.5' : 'px-1 py-0.5'

  return (
    <div
      onClick={onClick}
      style={{ backgroundImage: image ? `url("${image}")` : null }}
      className={`bg-neutral-100 bg-cover bg-no-repeat bg-center flex flex-col justify-between relative ${
        isMain ? `px-6 pb-6 ${mainHeight}` : 'h-52 px-3 pb-3'
      } ${isHoverable ? 'hover:scale-95 transition-transform cursor-pointer' : ''} ${className}`}
    >
      
      <div>
        {!!label && (
          <div className="inline-block bg-white px-3 py-1.5 uppercase font-bold">{label}</div>
        )}
      </div>
      
      <div className="space-y-1">
        {!!date && (
          <h3 className={isMain ? '' : 'text-sm'}>
            <span className={titleDateSpanClassName}>{date}</span>
          </h3>
        )}
        <h1 className={isMain ? 'text-3xl font-bold' : 'text-xl font-medium'}>
          <span className={titleDateSpanClassName}>{title}</span>
        </h1>
      </div>
      {!!onCloseClick && (
        <button
          type="button"
          className="bg-white w-10 h-10 rounded-full absolute top-3 right-3 flex items-center justify-center"
          onClick={onCloseClick}
        >
          <Icon path={mdiClose} wh="w-8 h-8" size={24} />
        </button>
      )}
    </div>
  )
}

export default ItemCard
