export default function Icon({ path, wh = 'w-6 h-6', size = null, className = '' }) {
  const iconSize = size ?? 16

  return (
    <span className={`inline-flex justify-center items-center ${wh} ${className}`}>
      <svg viewBox="0 0 24 24" width={iconSize} height={iconSize} className="inline-block">
        <path fill="currentColor" d={path} />
      </svg>
    </span>
  )
}
