import { useEffect, useState } from 'react'
import ItemCard from './components/ItemCard'
import Title from './components/Title'
import { useEventsData, useWorkshopsData } from './hooks/apiData'

function App() {
  const [modalItem, setModalItem] = useState(null)

  const { workshops } = useWorkshopsData()

  const { events } = useEventsData()

  const mainEvent = events.find((event) => event.acf.is_main_event)

  const otherEvents = events.filter((event) => !event.acf.is_main_event)

  const modalOpen = (item) => {
    window.location.hash = 'modal'
    setModalItem(item)
  }

  const modalClose = () => {
    window.location.hash = ''
    setModalItem(null)
  }

  useEffect(() => {
    window.onhashchange = () => {
      if (window.location.hash === '') {
        setModalItem(null)
      }
    }
  })

  return (
    <main className="max-w-6xl mx-auto">
      {modalItem && (
        <>
          <div
            className="bg-neutral-200/90 fixed inset-0 z-40 cursor-pointer"
            onClick={modalClose}
          ></div>
          <div className="absolute z-50 inset-x-0 bg-white max-w-4xl mx-auto">
            <ItemCard
              label={modalItem.acf.label}
              time={modalItem.acf.time}
              title={modalItem.title.rendered}
              image={modalItem.mktt_acf_image}
              mainHeight="h-48"
              isMain
              onCloseClick={modalClose}
            />
            <article
              className="prose max-w-none p-6"
              dangerouslySetInnerHTML={{ __html: modalItem.content.rendered }}
            />

            {!!modalItem.acf.ticket_url && (
              <div className="p-6">
                <a
                  href={modalItem.acf.ticket_url}
                  className="inline-block bg-neutral-800 text-white font-medium px-4 py-2 uppercase"
                >
                  Купить билет
                </a>
              </div>
            )}
          </div>
        </>
      )}

      <Title className="mt-12">Ближайшие мероприятия</Title>

      <div className={mainEvent ? 'grid grid-cols-1 lg:grid-cols-2 gap-6' : ''}>
        {!!mainEvent && (
          <ItemCard
            label={mainEvent.acf.label}
            time={mainEvent.acf.time}
            title={mainEvent.title.rendered}
            image={mainEvent.mktt_acf_image}
            onClick={() => modalOpen(mainEvent)}
            isMain
            isHoverable
          />
        )}

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          {otherEvents.map((event) => (
            <ItemCard
              key={event.id}
              label={event.acf.label}
              time={event.acf.time}
              title={event.title.rendered}
              image={event.mktt_acf_image}
              onClick={() => modalOpen(event)}
              isHoverable
            />
          ))}
        </div>
      </div>

      {!!workshops && (
        <div className="grid grid-cols-1 lg:grid-cols-4 gap-6 mb-24 mt-6">
          {workshops.map((workshop) => (
            <ItemCard
              key={workshop.id}
              label={workshop.acf.label}
              title={workshop.title.rendered}
              image={workshop.mktt_acf_image}
              onClick={() => modalOpen(workshop)}
              isHoverable
            />
          ))}
        </div>
      )}
    </main>
  )
}

export default App
